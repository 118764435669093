import React, { useEffect, useState } from "react";
import TabBar from "../components/Home/TabBar";
import Content from "../components/Home/Content";
import TabContent, {
  About,
  Contact,
  ImageContent,
  Poster,
} from "../components/Home/TabContent";
import team from "../image/team.jpg";
import salary from "../image/salary.png";
import product from "../image/product.jpg";
import { useScroll } from "ahooks";

const tabNameList = [
  "首页",
  "旗下产品",
  "关于晨升",
  "团队氛围",
  "薪酬福利",
  "联系我们",
];

const getTabContent = (index: number) => {
  if (index === 0) {
    return <Poster />;
  }
  if (index === 1) {
    return (
      <ImageContent
        height={1180}
        title={"旗下产品"}
        backgroundImage={`url(${product})`}
      />
    );
  }
  if (index === 2) {
    return <About />;
  }

  if (index === 3) {
    return (
      <ImageContent
        height={857}
        title={"团队氛围"}
        backgroundImage={`url(${team})`}
      />
    );
  }
  if (index === 4) {
    return (
      <ImageContent
        height={403}
        title={"薪资福利"}
        backgroundImage={`url(${salary})`}
      />
    );
  }
  if (index === 5) {
    return <Contact />;
  }
  return <div />;
};

export default function Home() {
  const [tabList, setTabList] = useState<{ name: string; active: boolean }[]>(
    tabNameList.map((it) => ({
      name: it,
      active: false,
    }))
  );
  const [contentList, setContentList] = useState<
    { name: string; height: number }[]
  >(
    tabNameList.map((it) => ({
      name: it,
      height: 0,
    }))
  );
  const position = useScroll(document);

  useEffect(() => {
    let index = 0;
    if (position.top) {
      for (let i = 0; i < contentList.length; i++) {
        const sum = contentList
          .filter((_, index) => index <= i)
          .map((it) => it.height)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );
        if (sum > position.top) {
          index = i;
          break;
        }
      }
    }
    setTabList((prevState) =>
      prevState.map((it, i) =>
        index === i
          ? { ...it, active: true }
          : {
              ...it,
              active: false,
            }
      )
    );
  }, [contentList, position.top]);

  return (
    <>
      <TabBar tabs={tabList} />
      <Content>
        {contentList.map((_, index) => (
          <TabContent
            recordHeight={(height) => {
              if (height) {
                setContentList((prevState) =>
                  prevState.map((it, i) =>
                    index === i ? { ...it, height } : it
                  )
                );
              }
            }}
            key={index}
            index={index}
          >
            {getTabContent(index)}
          </TabContent>
        ))}
      </Content>
    </>
  );
}
