import React from "react";
import ScrollTo from "react-scroll-into-view";
import logo from "../../image/logo.png";
import styles from "./TabBar.module.css";

export default function TabBar({
  tabs,
}: {
  tabs: { name: string; active: boolean }[];
}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.tab}>
        <div className={styles.logoContainer}>
          <img src={logo} className={styles.logo} alt={"logo"} />
        </div>
        <div className={styles.tabsContainer}>
          <ul className={styles.ul}>
            {tabs.map(({ name, active }, index) => (
              <li key={index} className={active ? styles.liActive : styles.li}>
                <ScrollTo selector={`#tabContent${index}`}>
                  <div> {name}</div>
                </ScrollTo>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
