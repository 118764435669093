import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { appName } from "./config";
import Home from "./screens/Home";

export default function App() {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{appName}</title>
        </Helmet>
        <Home />
      </div>
    </HelmetProvider>
  );
}
