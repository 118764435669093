import React, {ReactNode, useRef} from "react";
import {useSize, useUpdateEffect} from "ahooks";
import styles from "./TabContent.module.css";
import poster from "../../image/poster.png";

export default function TabContent({
                                       children,
                                       recordHeight,
                                       index,
                                   }: {
    children: ReactNode;
    recordHeight: (height?: number) => void;
    index: number;
}) {
    const ref = useRef(null);
    const size = useSize(ref);

    useUpdateEffect(() => {
        recordHeight(size.height);
    }, [size.height]);

    return (
        <div ref={ref} id={`tabContent${index}`} className={styles.wrapper}>
            {children}
        </div>
    );
}

function Poster() {
    return <div className={styles.poster} style={{
        backgroundImage: `url(${poster})`
    }}/>;
}

function About() {
    return (
        <div className={styles.main}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>关于晨升</div>
                <div className={styles.underline}/>
            </div>
            <div className={styles.aboutContentContainer}>
                <div className={styles.aboutContent}>
                    <div className={styles.itemText}>
                        于都晨升商贸有限公司，成立于2014年，
                    </div>
                    <div className={styles.itemText}>
                        我公司为各行各业提供专业的IT技术服务，经过6年多的努力与发展，
                    </div>
                    <div className={styles.itemText}>
                        已具一定的规模及实力，现拥有一支技术精湛的IT服务团队，
                    </div>
                    <div className={styles.itemText}>
                        以卓越的服务品质、专业安全的技术服务实力，为不同群体的用户提供更高更优质的IT服务。
                    </div>
                </div>
            </div>
        </div>
    );
}

function ImageContent({
                          title,
                          height,
                          backgroundImage,
                      }: {
    title: string;
    height: number;
    backgroundImage: string;
}) {
    return (
        <div className={styles.main}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.underline}/>
            </div>
            <div
                className={styles.imageContent}
                style={{
                    height,
                    backgroundImage,
                }}
            />
        </div>
    );
}

function Contact() {
    return (
        <div className={styles.contactContainer}>
            <div className={styles.main}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>联系我们</div>
                    <div className={styles.underline}/>
                </div>
                <div className={styles.contact}>
                    <div>电话：0797-6303305</div>
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>办公地点</div>
                    <div className={styles.underline}/>
                </div>
                <div className={styles.contact}>
                    <div>赣州：于都县贡江镇凤仪花园A栋704</div>
                </div>
            </div>

            <div className={styles.main}>
                <div className={styles.beian}>
                    <div>
                        <a
                            href="http://beian.miit.gov.cn/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            赣ICP备2021010927号-1
                        </a>{" "}
                        <a
                            style={{
                                marginLeft: 5,
                            }}
                            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36073102000098"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            赣公网安备 36073102000098号
                        </a>{" "}
                        | © 2014 - 2021 于都晨升商贸有限公司
                    </div>
                </div>
            </div>
        </div>
    );
}

export {Poster, About, ImageContent, Contact};
